import React from "react";
import Blog from "../../components/Blog";
import H1 from "../../components/h1";
import Par from "../../components/Par";
import SEO from "../../components/seo";

const title = "COVID-19 Planning";

export default () => (
	<Blog title={title} by="Dr Spiro Raftopoulos">
		<SEO title={title} keywords={[title]} type="article" description={title} />
		<H1>{title}</H1>
		<Par>
			We have the difficult goal of continuing to care for our patients whilst doing everything possible to minimise
			spreading of the coronavirus (COVID-19).
		</Par>
		<Par>Please note:</Par>
		<ul>
			<li>Our clinic will now offer consultations by Telephone or Telehealth as standard care. We are still able to see patients face-to-face if required however this will only be determined after your referral has been reviewed by your doctor.</li>
			<li>If face-to-face consultation is thought necessary, please notify our office if you are unwell with fever, cough or any respiratory symptoms and if you have had contact with anyone with confirmed coronavirus infection, recently returned from overseas or been overseas yourself.</li>
		</ul>
		<Par>If you are well and coming to your appointment:</Par>
		<ul>
			<li>Please don’t bring any unnecessary people with you.</li>
			<li>Please use the hand sanitiser at the desk on arrival.</li>
		</ul>

		<Par>
		Our secretarial staff are presently working remotely however they are still available to answer and respond to any of your queries Monday to Thursday 9 am to 5 pm and Friday’s 9 am to 1 pm by calling the practice phone on 9389 1733 or email info@advancedgiwa.com.au.
		</Par>
		<Par>
			For further information on COVID-19, please see{" "}
			<a href="https://www.healthdirect.gov.au/coronavirus" target="_blank" rel="noopener noreferrer">
				www.healthdirect.gov.au/coronavirus{" "}
			</a>
		</Par>
		<Par>We wish everyone the very best at this difficult time.</Par>
	</Blog>
);
